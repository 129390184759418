import React from "react"
import { graphql } from "gatsby"
import Gallery from "react-photo-gallery"
import map from "lodash/map"
import get from "lodash/get"

import Post from "../views/layouts/Post"

const GalleryPage = props => {
  const photos = map(
    get(props, "data.galleryJson.modules", []),
    item => ({
      src: get(item, "backgroundImage.file.url", ""),
      width: 4,
      height: 3,
    })
  )

  return (
    <Post location="硬件设备">
      <Gallery photos={photos} direction={"column"} />
    </Post>
  )
}

export default GalleryPage

export const pageQuery = graphql`
  query GalleryQuery {
    galleryJson: contentfulPage(slug: { eq: "gallery" }) {
      modules {
        ... on ContentfulLayoutHeroImage {
          id
          backgroundImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
